import { ClipLoader } from 'react-spinners'
import {theme} from 'antd'

export default (props) => {
  const {token} = theme.useToken()

  return (
    <div style={{
      position: 'fixed',
      left: '50%',
      top: '40%',
      transform: 'translate(-50%,-50%)'
    }}>
      <ClipLoader color={token.colorPrimary} />
    </div>
  )
}
